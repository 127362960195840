
import http from '@/utils/http'
import { defineComponent, onMounted, ref } from 'vue'
import { format_date } from '../../../utils/index'
import { message } from 'ant-design-vue'

const columns = [
  {
    title: 'App类型',
    dataIndex: 'app_type',
    key: 'app_type',
    align: 'center'
  },
  {
    title: '下载地址',
    dataIndex: 'download_url',
    key: 'download_url',
    align: 'center'
  },
  {
    title: '最新版本',
    dataIndex: 'last_ver',
    key: 'last_ver',
    align: 'center'
  },
  {
    title: '最小版本',
    dataIndex: 'min_ver',
    key: 'min_ver',
    align: 'center'
  },
  {
    title: '更新内容',
    dataIndex: 'show_text',
    key: 'show_text',
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: '',
    key: '',
    slots: { customRender: 'edit' },
    align: 'center'
  }
]

export default defineComponent({
  name: 'AppVersion',
  setup () {
    const total = ref(0)
    const currentPage = ref(1)
    const dataSource = ref([])
    const iconLoading = ref(false)
    const visible = ref(false)
    const form = ref({ app_key: '' })
    const modalData = ref({
      download_url: '',
      last_ver: '',
      min_ver: '',
      show_text: '',
      app_type: ''
    })
    const curUserName = ref('')

    const paginationChange = (page: any) => {
      currentPage.value = page
      getList()
    }

    const getList = () => {
      http(`/tokenapi/app_ver?page=${currentPage.value}`, {
        method: 'get',
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          dataSource.value = res.data
          total.value = dataSource.value.length
        }
      })
    }

    const setting = (data: any) => {
      visible.value = true
      modalData.value = {
        download_url: data.download_url,
        last_ver: data.last_ver,
        min_ver: data.min_ver,
        show_text: data.show_text,
        app_type: data.app_type
      }
    }

    const handleOk = () => {
      http(`/tokenapi/app_ver`, {
        method: 'post',
        body: JSON.stringify({
          ...modalData.value
        }),
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          visible.value = false
          getList()
        }
        message.info(res.msg)
      })
    }

    onMounted(() => {
      getList()
    })

    return {
      columns,
      total,
      currentPage,
      paginationChange,
      dataSource,
      iconLoading,
      form,
      format_date,
      visible,
      setting,
      modalData,
      handleOk
    }
  }
})
