<template>
  <div class="app_key">
    <a-table :dataSource="dataSource" :columns="columns" :row-key="record => record.app_type" :size="'small'" :pagination="false" :loading="iconLoading">
      <template #Type="{ record }">
        <span>{{ record.Type === '0' ? '管理员' : '普通用户' }}</span>
      </template>
      <template #Createtime="{ record }">
        <span>{{ format_date(new Date(Number(record.Createtime + '000')), 'YYYY-MM-DD hh:mm:ss') }}</span>
      </template>
      <template #edit="{ record }">
        <a-button type="link" @click="setting(record)">配置</a-button>
      </template>
    </a-table>
    <a-pagination
      :total="total"
      :show-total="total => `总共 ${total} 条`"
      :page-size="100"
      :size="'small'"
      v-model:current="currentPage"
      @change="paginationChange"
    />
    <a-modal v-model:visible="visible" title="配置" @ok="handleOk" okText="保存" cancelText="取消">
      <div style="margin-bottom: 10px">
        <span>下载地址：</span>
        <a-input style="display: inline; width: 85%" v-model:value="modalData.download_url" />
      </div>
      <div style="margin-bottom: 10px">
        <span>最新版本：</span>
        <a-input style="display: inline; width: 85%" v-model:value="modalData.last_ver" />
      </div>
      <div style="margin-bottom: 10px">
        <span>最小版本：</span>
        <a-input style="display: inline; width: 85%" v-model:value="modalData.min_ver" />
      </div>
      <div style="margin-bottom: 10px">
        <span>更新内容：</span>
        <a-input style="display: inline; width: 85%" v-model:value="modalData.show_text" />
      </div>
    </a-modal>  
  </div>
</template>

<script lang="ts">
import http from '@/utils/http'
import { defineComponent, onMounted, ref } from 'vue'
import { format_date } from '../../../utils/index'
import { message } from 'ant-design-vue'

const columns = [
  {
    title: 'App类型',
    dataIndex: 'app_type',
    key: 'app_type',
    align: 'center'
  },
  {
    title: '下载地址',
    dataIndex: 'download_url',
    key: 'download_url',
    align: 'center'
  },
  {
    title: '最新版本',
    dataIndex: 'last_ver',
    key: 'last_ver',
    align: 'center'
  },
  {
    title: '最小版本',
    dataIndex: 'min_ver',
    key: 'min_ver',
    align: 'center'
  },
  {
    title: '更新内容',
    dataIndex: 'show_text',
    key: 'show_text',
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: '',
    key: '',
    slots: { customRender: 'edit' },
    align: 'center'
  }
]

export default defineComponent({
  name: 'AppVersion',
  setup () {
    const total = ref(0)
    const currentPage = ref(1)
    const dataSource = ref([])
    const iconLoading = ref(false)
    const visible = ref(false)
    const form = ref({ app_key: '' })
    const modalData = ref({
      download_url: '',
      last_ver: '',
      min_ver: '',
      show_text: '',
      app_type: ''
    })
    const curUserName = ref('')

    const paginationChange = (page: any) => {
      currentPage.value = page
      getList()
    }

    const getList = () => {
      http(`/tokenapi/app_ver?page=${currentPage.value}`, {
        method: 'get',
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          dataSource.value = res.data
          total.value = dataSource.value.length
        }
      })
    }

    const setting = (data: any) => {
      visible.value = true
      modalData.value = {
        download_url: data.download_url,
        last_ver: data.last_ver,
        min_ver: data.min_ver,
        show_text: data.show_text,
        app_type: data.app_type
      }
    }

    const handleOk = () => {
      http(`/tokenapi/app_ver`, {
        method: 'post',
        body: JSON.stringify({
          ...modalData.value
        }),
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          visible.value = false
          getList()
        }
        message.info(res.msg)
      })
    }

    onMounted(() => {
      getList()
    })

    return {
      columns,
      total,
      currentPage,
      paginationChange,
      dataSource,
      iconLoading,
      form,
      format_date,
      visible,
      setting,
      modalData,
      handleOk
    }
  }
})
</script>

<style lang="scss">
.app_key {
  .search-bar {
    height: 40px;
    margin-bottom: 15px;

    .label {
      width: 40px;
      display: inline;

      .anticon {
        margin: 0 3px;
      }
    }

    .ant-input {
      display: inline;
      width: 250px;
      margin: 0 10px;
    }

    .ant-btn {
      margin: 0 10px;
    }
  }

  .ant-pagination {
    margin: 20px 0;
    display: flex;
    justify-content: flex-end;
  }
}

.editable-cell {
  position: relative;
  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 24px;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 5px;
  }

  .editable-cell-icon,
  .editable-cell-icon-check {
    position: absolute;
    right: 0;
    width: 20px;
    cursor: pointer;
  }

  .editable-cell-icon {
    margin-top: 4px;
    display: none;
  }

  .editable-cell-icon-check {
    line-height: 28px;
  }

  .editable-cell-icon:hover,
  .editable-cell-icon-check:hover {
    color: #108ee9;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
}
.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}
</style>
